export const ROUTES = {
  home: '/',
  job: '/job',
  jobs: '/jobs',
  termsOfUse: '/terms-of-use',
  privacyPolicy: '/privacy',

  redirect: '/redirect',
  signup: '/signup',
  signin: '/signin',
  unsubscribe: '/unsubscribe',
  profile: '/profile',

  emailFrequency: '/email-frequency',
  appcastRedirect: '/redirect/appcast-redirect',
};

export const API_ROUTES = {
  getSignedUrl: '/api/get-signed-url',
  getDownloadUrl: '/api/get-download-url',
  stopLoadingJob: '/api/stop-loading-job',
  getBreadcrumbsCN: '/api/get-breadcrumbs-cn',
  generateOptionsForUserCN: '/api/generate-options-for-user-cn',
};

export const PROJECTS_ROUTES = {
  kochDavis: { shortPath: 'kd', site: 'https://kochdavisjobs.com' },
  mcCarthy: { shortPath: 'mc', site: 'https://mccarthysearch.com' },
  johnsonJobs: { shortPath: 'jc', site: 'https://johnsonjobs.com' },
  kimbleGroup: { shortPath: 'kg', site: 'https://kimblegroup.com' },
  davidCareers: { shortPath: 'dc', site: 'https://davidcareers.com' },
  canvasNursing: { shortPath: 'cn', site: 'https://canvasnursing.com' },
};
